<template>
  <div :style="cssVars">
    <v-layout>
      <v-main :style="'color:' + textColour + '; background-color:' +  backgroundColour">
        <v-img :class="{
            'v-image--sm': this.$vuetify.breakpoint.smAndDown,
            'v-image--md': this.$vuetify.breakpoint.mdAndDown
          }"
          :src="event && event.backgroundImage" min-height="100%">
        <Toolbar :boxOffice="boxOffice" :event="event"/>
        <Loading v-if="!boxOffice" />
        <div v-else-if="boxOffice && !notFound" class="inner">
          <div class="logo">
            <img v-if="boxOffice.logoImage" :src="boxOffice.logoImage" style="display:table" class="mx-auto">
            <pages-heading v-else class="text-center mb-5">{{boxOffice.name}}</pages-heading>
            <div class="mt-4">
              <span v-html="boxOffice.description"/>
            </div>
          </div>
          <!--queue v-if="queued || !loaded" @queued="queueUpdate" :loaded="loaded" class="mt-5"/-->
          <v-row v-if="boxOffice && event">
            <v-col cols="12">
              <div class="aside">
                <h2 class="heading-large text-center">{{event.name}}</h2>
                <div style="display:table" class="mx-auto">
                  <div v-if="boxOffice.name != 'sparklejumpropequeen'">
                    <div v-if="eventTimes && eventTimes.length > 5">
                      <h3 v-if="firstEventTime || lastEventTime" class="mt-2">
                        <v-icon class="mt-n1 mr-1" :color="textColour">mdi-clock-time-four</v-icon>
                        <span>
                          {{utils.timezoneDateToShortString(firstEventTime.start, firstEventTime.timezone)}} to {{utils.timezoneDateToShortString(lastEventTime.end, lastEventTime.timezone, utils.sameDay(firstEventTime.start, lastEventTime.end) ? 'h:mma' : null)}}
                        </span>
                      </h3>
                    </div>
                    <div v-else>
                      <div v-for="(eventTime, i) in eventTimes" :key="i">
                        <h3 class="mt-2">
                          <v-icon class="mt-n1 mr-1" :color="textColour">mdi-clock-time-four</v-icon>
                          <span style="font-size:14px;">
                            {{utils.timezoneDateToShortString(eventTime.start, eventTime.timezone)}} to {{utils.timezoneDateToShortString(eventTime.end, eventTime.timezone, utils.sameDay(eventTime.start, eventTime.end) ? 'h:mma' : null)}}
                          </span>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <h3 v-if="event.location" style="display:table" class="mx-auto">
                  <v-icon class="mt-n1 mr-1" :color="textColour">mdi-map-marker</v-icon> {{event.location}}
                </h3>
                <div v-if="event.eventImage || event.description" class="mt-2">
                  <img class="mx-auto" style="display:table" :src="getCloudinaryImage({url: event.eventImage, width: 1200})"> 
                  <span class="mr-2 ml-2" v-html="event.description"/>
                </div>
              </div>
            </v-col>
            <v-col cols="12">
              <h2 v-if="!loaded || (products && products.length)" class="mb-4">Products</h2>
              <Loading v-if="!loaded" />
              <div v-else class="cards">
                <v-card
                  outlined
                  tile
                  v-for="product in products"
                  :key="product.id"
                  class="card"
                  :class="{
                    'card--noBackground': isThereNoBackgroundImage
                  }"
                >
                  <v-card-title>{{product.name}}</v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <div v-if="product.description">{{product.description}} </div> 
                        <div v-if="product.image" @click="showProduct(product)"             >
                          <img class="mx-auto mt-4" style="cursor: pointer" :src="getCloudinaryImage({url: product.image, width: 150})"> 
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-text class="mt-auto">
                    <v-row>
                      <v-col cols="12">
                        <div class="actions" v-if="(!product.onSaleFrom || utils.dateNow() > product.onSaleFrom) && 
                                  (!product.onSaleTo || utils.dateNow() < product.onSaleTo) && (product.quantity - product.sold > 0)">
                          <div class="actions__info"> 
                            <div>
                              <span class="price">{{utils.numberWithCommas(utils.priceDisplay(product.price, product.currency))}} </span>
                            </div>
                            <div v-if="product.onSaleFrom && utils.dateNow() < product.onSaleFrom">Will go on sale at {{utils.dateToShortString(product.onSaleFrom)}}</div>
                            <div v-else-if="product.onSaleTo && utils.dateNow() > product.onSaleTo">Sorry, sales ended at  {{utils.dateToShortString(product.onSaleTo)}}</div>
                            <div v-else-if="product.showAvailability">{{product.quantity - product.sold}} available</div>
                          </div>
                          <div v-if="product.quantity - product.sold < 1" class="card-label">Sold out</div>
                          <div v-else class="actions__cta">
                            <v-select class="mb-1" value=1 style="width:70px;" label="Amount" v-model="requested[product.id]" :items="numProducts(product)" hide-details>
                              <template v-slot:selection="{ item }">
                                <span class="display-2">{{item}}</span>
                              </template>
                            </v-select>
                            <v-select v-if="product.options" class="mb-1" label="Size" style="width:70px;" v-model="option[product.id]" :items="options[product.id]" hide-details>
                              <template v-slot:selection="{ item }">
                                <span class="display-2">{{item}}</span>
                              </template>
                              <template v-slot:item="{ item }">
                                <span class="display-2">{{item}}</span>
                              </template>
                            </v-select>
                            <div>
                              <Button @click="selectProduct(product, requested[product.id], option[product.id])">Add <v-icon x-small class="mr-2">mdi-cart</v-icon></Button>
                            </div>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </div>
              <v-dialog dark v-if="product" v-show="productDialog" v-model="productDialog" persistent max-width="600px">
                <v-card outlined tile :style="cssVars">
                  <v-card-title>{{product.name}}
                    <v-spacer></v-spacer>
                    <v-icon large @click="productDialog = false">mdi-close</v-icon>
                  </v-card-title>
                  <v-card-text>
                    <img class="mx-auto" :src="getCloudinaryImage({url: product.image, width: 550})"> 
                    <div style="text-align:center">{{product.description}}</div>
                    <div style="text-align:center">{{utils.priceDisplay(product.price, product.currency)}} </div>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col cols="12">
              <h2 class="mb-4 mt-8">Tickets</h2>
              <Loading v-if="!loaded" />
              <div v-else-if="eventTimes && !eventTimes.length">
                <p>Sorry, you can't buy tickets for this event as there are no times available.</p>
              </div>
              <div v-else-if="!eventTime">
                <div class="cards">
                  <v-card
                    outlined
                    tile
                    class="mt-0 card"
                    :class="{
                      'card--noBackground': isThereNoBackgroundImage
                    }"
                  >
                    <v-card-title>Choose Date for {{event.name}}</v-card-title>
                    <v-card-text>
                      <v-row class="d-flex justify-center mt-5" v-for="(eventTime, i) in eventTimes" :key="i">
                        <v-col cols="8">
                          <div>
                            {{utils.timezoneDateToShortString(eventTime.start, eventTime.timezone)}} <br>to {{utils.timezoneDateToShortString(eventTime.end, eventTime.timezone, utils.sameDay(eventTime.start, eventTime.end) ? 'h:mma' : null)}}
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <Button v-if="availableTickets(null, eventTime) > 0" @click="showTicketsDialog(eventTime)">
                            <template v-if="$vuetify.breakpoint.smAndUp">Tickets</template>
                            <template v-else>Buy</template>
                          </Button>
                          <Button v-else disabled >Sold Out</Button>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </div>
              </div>
              <div v-else>
                <div v-if="eventTimes && eventTimes.length > 1" class="mb-5 d-flex">
                  <v-spacer/>
                  <Button @click='eventTime = null'>Browse other Dates / Times</Button>
                  <v-spacer/>
                </div>
                <div class="cards">
                  <v-card
                    outlined
                    tile
                    v-for="ticketType in ticketTypes"
                    :key="ticketType.id"
                    class="card"
                    :class="{
                      'sold-out': availableTickets(ticketType, eventTime) < 1 || ticketType.onSaleTo && utils.dateNow() > ticketType.onSaleTo,
                      'card--wide': isTicketWideStyle(ticketType),
                      'card--noBackground': isThereNoBackgroundImage
                    }"
                  >
                    <v-card-title>{{ticketType.name}}</v-card-title>
                    <v-card-text>
                      <event-time-display v-if="boxOffice.name != 'sparklejumpropequeen'" v-model="eventTime" :color="textColour" />
                      <v-row>
                        <v-col cols="12" class="mt-5">
                          <div style="text-align: left">

                            <div
                              v-if="richDescription(ticketType)"  
                              class="content mt-4 mb-4 columnContainer"
                            >
                              <div
                                v-if="ticketType.image"
                                class="column"
                              >
                                <img :src="ticketType.image" alt="" />
                              </div>
                              <div
                                v-if="ticketType.richDescription"
                                class="column"
                              >
                                <v-expansion-panels
                                >
                                  <v-expansion-panel
                                    v-for="(c, i) in ticketType.content"
                                    :key="i"
                                  >
                                    <v-expansion-panel-header>
                                      {{ c.title }}
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                      <div v-html="c.value" style="opacity:0.8;" />
                                    </v-expansion-panel-content>
                                  </v-expansion-panel>
                                </v-expansion-panels>
                              </div>
                            </div>
                            <div v-else-if="ticketType.content && ticketType.content[0]">
                              <h3 v-html="ticketType.content[0].title" />
                              <div v-html="ticketType.content[0].value" />
                              <img v-if="ticketType.image" :src="ticketType.image" alt="" />
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="12">
                          <div class="actions" v-if="boxOffice.isUserOwner || ((!ticketType.onSaleFrom || utils.dateNow() > ticketType.onSaleFrom) && (!ticketType.onSaleTo || utils.dateNow() < ticketType.onSaleTo))">
                            <template v-if="availableTickets(ticketType, eventTime) < 1">
                              <div class="actions__info"></div>
                              <div class="actions__cta">
                                <div class="card-label">Sold out</div>
                              </div>
                              <div v-if="!!soldoutUrl" class="actions__cta">
                                <a :href="soldoutUrl" target="_blank">Sold out - check for more tickets here <v-icon>mdi-open-in-new</v-icon></a>
                              </div>
                            </template>

                            <template v-else>
                              <div class="actions__info">
                                <div>
                                  <span class="price">{{utils.numberWithCommas(utils.priceDisplay(ticketType.price, ticketType.currency))}} </span>
                                  <span v-if="ticketType.bookingFee"> + {{utils.priceDisplay(ticketType.bookingFee, ticketType.currency)}} booking fee</span>
                                </div>
                                <div v-if="ticketType.onSaleFrom && utils.dateNow() < ticketType.onSaleFrom">Tickets will go on sale at {{utils.dateToShortString(ticketType.onSaleFrom)}}</div>
                                <div v-else-if="ticketType.onSaleTo && utils.dateNow() > ticketType.onSaleTo" class="card-label">Ticket sales ended at {{utils.dateToShortString(ticketType.onSaleTo)}}</div>
                                <div v-else-if="availableTickets(ticketType, eventTime) > 0 && ticketType.showAvailability">{{availableTickets(ticketType, eventTime)}} tickets available</div>
                              </div>
                              <div v-if="allowBuyTicket(ticketType, eventTime)" class="actions__cta">
                                <div>
                                  <v-select class="mb-1" color="textColor" value=1 label="Amount" style="width:70px;" v-model="requested[ticketType.id]" :items="numTickets(ticketType, eventTime)" hide-details>
                                    <template v-slot:selection="{ item }">
                                      <span class="display-2">{{item}}</span>
                                    </template>
                                  </v-select>
                                </div>
                                <div>
                                  <Button v-if="boxOffice" @click="selectTicket(ticketType, requested[ticketType.id])">Add <v-icon x-small class="mr-2">mdi-cart</v-icon></Button>
                                </div>
                              </div>
                            </template>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
        <div v-if="notFound">
          <v-main>
            <v-container>
            <v-row align="center" justify="center">
                <v-col class="text-center">
                  <pages-heading>404 - Not Found</pages-heading>
                  <Button v-if="boxOffice" class="mt-5" @click="boxOfficeLink()">Box Office</Button>
                </v-col>
              </v-row>
            </v-container>
          </v-main>
        </div>
        <div v-if="boxOffice" class="mt-10 mb-12 pb-12 text-center">
        </div>
        <contact class="mt-auto" :boxOffice="boxOffice" :event="event"/>
      </v-img>
    </v-main>
  </v-layout>
  <checkout v-if="checkout" :event="event" :boxOffice="boxOffice" @closed="checkout=false" @closedConfirmed="checkoutComplete()"/>
</div>
</template>
  
<script>
import Api from '@/services/Api.js'; 
import Utils from '@/services/Utils.js'
import EventUtils from '@/services/EventUtils.js';
import Store from '@/services/Store.js';
import { hexToRgb } from '@/helpers/common'
import { getCloudinaryImage } from '@/helpers/cloudinary'
import { getCurrencyPrefix } from '@/helpers/currency'

!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');

  export default {
    components: {
      PagesHeading: () => import('../pages/components/Heading'),
      EventTimeDisplay: () => import('../dashboard/components/EventTimeDisplay'),
      CheckoutButton: () => import('./CheckoutButton'),
      Checkout: () => import('./Checkout'),
      Contact: () => import('./Contact'),
      Button: () => import('./Button'),
      Loading: () => import('./Loading'),
      Toolbar: () => import('./Toolbar')
    },  

    metaInfo() {
      return this.meta;
    },

    data () {
      return {
        getCurrencyPrefix,
        meta:  {
          title: "Openstage",
        },
        getCloudinaryImage,
        loaded: false,
        checkout: false,
        ticketsDialog: false,
        productDialog: false,
        eventTimeDialog: false,
        textColour: null,
        backgroundColour: null,
        lowestPrice: 0,
        highestPrice: 0,
        boxOffice: null,
        firstEventTime: null,
        lastEventTime: null,
        backgroundImage: "",
        notFound: false,
        order: Store.order,
        requested: {},
        option: {},
        options: {},
        availability: {},
        eventTimes: null,
        ticketTypes: null,
        products: [],
        product: null,
        event: null,
        eventTime: null,
        queued: true,
        logoImage: null
      }
    },
    
    async created() {
      this.load();
    },

    mounted () {
      const htmlRoot = document.documentElement

      if(htmlRoot) {
        if (this.backgroundColour) {
          const backgroundColourRgb = hexToRgb(this.backgroundColour)
          htmlRoot.style.setProperty('--background-color', this.backgroundColour)
          htmlRoot.style.setProperty('--background-color-rgb', `${backgroundColourRgb.r}, ${backgroundColourRgb.g}, ${backgroundColourRgb.b}`)
        }
      }
    },

    methods: {

      richDescription (ticketType) {
        return ticketType?.content?.length > 1
      },

      isTicketWideStyle (ticket) {
        return ticket.content && ticket.image
      },

      queueUpdate(value) {
        this.queued = value
        if (!this.queued) this.loadEvent();
      },

      showProduct(product) {
        this.product = product;
        this.productDialog = true;
      },

      async load() {
        this.boxOffice = await Api.post(this, "BoxOffice", "read", {path: this.$route.params.boxOfficePath}, true);
        if (!this.boxOffice) {
          this.notFound = true;
          return;
        }
        this.logoImage = this.boxOffice.logoImage
        this.loadEvent();
      },

      async loadEvent() {
        this.event = await Api.post(this, "Event", "read", {path: this.$route.params.eventPath, boxOfficeId: this.boxOffice.id}, true);
        if (!this.event) {
          this.notFound = true;
          return;
        }
        this.textColour = this.event.textColour;
        this.backgroundColour = this.event.backgroundColour;
        var content = this.boxOffice.name + " " + this.event.name +  " " + this.event.location
        this.meta = {
          title: this.event.name,
          meta: [
            { vmid: 'description', name: 'description', content: content },
            { name: "title", property: 'og:title', content: content },
            { name: "description", property: 'og:description', content: content },
            { property: 'twitter:title', content: content },
            { property: 'twitter:description', content: content },
          ]
        };
        if (this.event.facebookPixel) {
          console.log("logging facebook pixel")
          fbq('init', this.event.facebookPixel);
          fbq('track', 'PageView');
        }
        await this.readAvailability();
        if (this.$route.params.eventTimeId) {
          this.eventTime = await Api.post(this, "EventTime", "read", {id: this.$route.params.eventTimeId}, true);
          if (this.eventTime) {
            this.firstEventTime = this.eventTime;
            this.lastEventTime = this.eventTime;
          }
        }
        this.loaded = true
      },

      showTicketsDialog(eventTime) {
        this.eventTime = eventTime;
      },

      checkoutComplete() {
        this.ticketsDialog = false;
        this.checkout = false;
        this.eventTimeDialog = false;
        this.readAvailability();
      },

      async readAvailability() {
        await this.readTicketTypes();
        await this.readProducts();
        await this.readEventTimes();
        var availabilities = await Api.post(this, "Event", "availability", this.event);
        this.availability = {};
        for (var avail of availabilities) {
          var existing = this.availability[avail.eventTimeId];
          if (!existing) {
            existing = {};
          }
          existing[avail.ticketTypeId] = avail;
          this.availability[avail.eventTimeId] = existing;
        }
      },

      availableTickets(ticketType, eventTime, ignoreMyOrder) {
        if (!eventTime) {
          return 0;
        }
        var eventTimeAvailability = this.availability[eventTime.id];
        if (!eventTimeAvailability) {
          return 0;
        }
        if (ticketType == null) {
          var available = 0;
          for (var ticketTypeAvailability of Object.values(eventTimeAvailability)) {
            available += ticketTypeAvailability.availableTickets;
          }
          return available;
        } else {
          var ticketTypeAvailability = eventTimeAvailability[ticketType.id];
          if (!ticketTypeAvailability) {
            return 0;
          }
          if (!ignoreMyOrder) {
            let existing = this.findOrderItem(ticketType, eventTime);
            if (existing) {
              return ticketTypeAvailability.availableTickets - existing.quantity;
            }
          }
          return ticketTypeAvailability.availableTickets;
        }
      },

      async readTicketTypes() {
        this.ticketTypes = await Api.post(this, "TicketType", "list", this.event);
        this.lowestPrice = 999999;
        this.highestPrice = 0;
        for (var ticketType of this.ticketTypes) {
          this.requested[ticketType.id]=1;
          if (ticketType.richDescription) {
            const jsonDesc = JSON.parse(ticketType.richDescription)
            ticketType.content = jsonDesc
          }
          if (ticketType.price < this.lowestPrice) {
            this.lowestPrice = ticketType.price;
          }
          if (ticketType.price > this.highestPrice) {
            this.highestPrice = ticketType.price;
          }
          if (!ticketType.currency) {
            ticketType.currency = this.boxOffice?.currency || 'GBP';
          }
        }
        if (this.lowestPrice == 999999) {
          this.lowestPrice = 0;
        }
      },

      async readProducts() {
        let allProducts = await Api.post(this, "Product", "list", this.boxOffice);
        this.products = allProducts.filter(f => f.show)
        for (var product of allProducts) {
          if (product.show) {
            this.requested[product.id]=1;
            if (product.options) {
              this.options[product.id] = product.options.split(",")
              this.option[product.id] = this.options[product.id][0]
            }
            if (!product.currency) {
              product.currency = this.boxOffice?.currency || 'GBP';
            }
          }
        }
      },

      async readEventTimes() {
        var allEventTimes = await Api.post(this, "EventTime", "list", this.event);
        this.eventTimes = [];
        var now = Utils.dateNow();
        for (var eventTime of allEventTimes) {
          if (eventTime.end > now) {
            this.eventTimes.push(eventTime);
          }
        }
        if (this.eventTimes && this.eventTimes.length == 1) {
          this.eventTime = this.eventTimes[0];
        } else {
          this.eventTime = null;
        }
        if (this.eventTimes && this.eventTimes.length > 0) {
          this.firstEventTime = this.eventTimes[0];
          this.lastEventTime = this.eventTimes[this.eventTimes.length-1];
        }
      },

      plural(number) {
        if (number > 1) {
          return "s";
        }
      },

      async selectTicket (ticketType, quantity) {
        let existing = this.findOrderItem (ticketType, this.eventTime);
        if (existing != null) {
          existing.quantity += quantity;
          this.checkout = true;
          return;
        }
        let orderItem = {};
        orderItem.ticketType = ticketType;
        orderItem.ticketTypeId = ticketType.id;
        orderItem.event = this.event;
        orderItem.eventId = this.event.id;
        orderItem.eventTime = this.eventTime;
        orderItem.eventTimeId = this.eventTime.id;
        orderItem.quantity = quantity;
        orderItem.eventUrl = EventUtils.makeURL(this.boxOffice, this.event, this.eventTime);
        orderItem.price = ticketType.price + ticketType.bookingFee;
        this.order.items.push(orderItem);
        this.checkout = true;
      },

      async selectProduct (product, quantity, productOption) {
        let existing = this.findOrderProductItem(product, productOption);
        if (existing != null) {
          existing.quantity += quantity;
          this.checkout = true;
          return;
        }
        let orderItem = {};
        orderItem.product = product;
        orderItem.productId = product.id;
        orderItem.quantity = quantity;
        orderItem.price = product.price;
        orderItem.collectAddress = product.collectAddress;
        if (productOption) {
          orderItem.productOption = productOption;
        }
        this.order.items.push(orderItem);
        this.checkout = true;
      },

      findOrderItem (ticketType, eventTime) {
        if (this.order && this.order.items) {
          for (let item of this.order.items) {
            if (item.ticketTypeId == ticketType.id && item.eventTimeId == eventTime.id)  {
              return item;
            }
          }
        }
      },

      findOrderProductItem (product, option) {
        if (this.order && this.order.items) {
          for (let item of this.order.items) {
            if (item.productId == product.id && item.productOption == option)  {
              return item;
            }
          }
        }
      },

      numTickets(ticketType, eventTime) {
        var available = this.availableTickets(ticketType, eventTime);
        var max = ticketType.maximumPerOrder;
        if (!max || max > available) {
          max = available;
        }
        var array = [];
        for (var i=0; i<max; i++) {
          array[i]=i+1;
        }
        return array;
      },

      numProducts(product) {
        var available = product.quantity - product.sold;
        var max = product.maximumPerOrder;
        if (!max || max > available) {
          max = available;
        }
        var array = [];
        for (var i=0; i<max; i++) {
          array[i]=i+1;
        }
        return array;
      },

      productButtonLabel(quantity) {
        return "Buy " + quantity;
      },

      boxOfficeLink() {
        this.$router.push("/boxOffice/" + this.$route.params.boxOfficePath)
      },

      allowBuyTicket (ticketType, eventTime) {
        return !(ticketType.onSaleFrom && this.utils.dateNow() < ticketType.onSaleFrom) && !(ticketType.onSaleTo && this.utils.dateNow() > ticketType.onSaleTo) && (this.availableTickets(ticketType, eventTime) > 0)
      },
    },

    computed: {

      isThereNoBackgroundImage () {
        return !this.event?.backgroundImage
      },

      utils: function () {
        return Utils;
      },

      eventUtils: function () {
        return EventUtils;
      },

      collectCard() {
        if (this.cash) {
          return false;
        }
        if (this.totalCost == 0) {
          return false;
        }
        return true;
      },
      styles () {
        const paddingTop = 50
        const paddingBottom = 50
        return {
          padding: `${paddingTop}px 0 ${paddingBottom}px 0`,
        }
      },

      cssVars() {
        const bg_rgb = hexToRgb(this.backgroundColour || '#FFFFFF')
        const text_rgb = hexToRgb(this.textColour || '#000000')
        const artistJS = window.location.href.toLowerCase().includes('johnnyswim')
        const ffTitle = artistJS ? `Glaschu, Oxygen, sans-serif` : `Oxygen, sans-serif`
        const ffText =  artistJS ? `SaaSeriesFD, Oxygen, sans-serif` : `Oxygen, sans-serif`

        return {
          '--text-color': this.textColour,
          '--text-color-rgb': `${text_rgb.r}, ${text_rgb.g}, ${text_rgb.b}`,
          '--background-color': this.backgroundColour,
          '--background-color-rgb': `${bg_rgb.r}, ${bg_rgb.g}, ${bg_rgb.b}`,
          '--font-family-title': ffTitle,
          '--font-family-text': ffText,
          '--button-background': this.textColour,
          '--button-color': this.backgroundColour,
        }
      },

      soldoutUrl() { return this.event?.soldoutUrl || null },
    },
  }
</script>

<style lang="scss" scoped>
.column {
  font-family: var(--font-family-text)
    }
.v-card__title, h2, .heading, .price {
  font-family: var(--font-family-title)
    }
  .v-card__text, .v-card__title  {
    word-break: normal /* maybe !important  */
    }
  .v-sheet.v-card {
    border: thick solid;
    color: var(--text-color);
    background-color: var(--background-color);
    }
  div {
    color: var(--text-color)
    }
  .v-btn {
    color: var(--text-color)
    }
  .display-2 {
    color: var(--text-color)
    }
  .v-text-field  {
    color: var(--text-color)
    }
  .card {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(var(--background-color-rgb),.5);
      backdrop-filter: blur(.5em);
      border-radius: 24px;
      opacity: 0.9;
      z-index: -1;
    }
  }
  .sold-out {
    &:before {
      background: rgba(0, 0, 0, 0.6);
      z-index: 0;
    }
    .card-label {
      position: absolute;
      top: -4px;
      right: -4px;
      border-radius: 8px;
      background: $negative;
      padding: 2px 6px;
      color: white;
    }
  }
</style>
